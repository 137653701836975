<template>
  <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
    <v-container>
      <v-row dense>
        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
          <BaseImageFile v-model="item.logo64" />
        </v-col>
        <v-col cols="12" xl="9" lg="9" md="9" sm="12">
          <v-row dense>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-text-field
                id="socialName"
                name="socialName"
                label="Razão Social"
                dense
                outlined
                required
                :rules="[required]"
                v-model="item.socialName"
              />
            </v-col>
            <v-col cols="12" xl="8" lg="8" md="8" sm="8">
              <v-text-field
                id="fantasyName"
                name="fantasyName"
                label="Nome Fantasia"
                dense
                outlined
                required
                :rules="[required]"
                v-model="item.fantasyName"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="4">
              <v-text-field
                id="document"
                name="document"
                label="CNPJ"
                dense
                outlined
                required
                :rules="[required, cnpj]"
                v-mask="'##.###.###/####-##'"
                v-model="item.document"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" xl="2" lg="2" md="2" sm="4">
          <BaseZipcode
            id="zipCode"
            name="zipCode"
            label="CEP"
            required
            :rules="[required, zipcode]"
            v-model="item.zipCode"
            @fill="fill($event)"
          />
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="8" sm="8">
          <v-text-field
            id="address"
            name="address"
            label="Endereço"
            dense
            outlined
            required
            :rules="[required]"
            v-model="item.address"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="2">
          <v-text-field
            id="number"
            name="number"
            label="Número"
            type="number"
            dense
            outlined
            required
            :rules="[required]"
            v-model="item.number"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="5">
          <v-text-field
            id="complement"
            name="complement"
            label="Complemento"
            dense
            outlined
            v-model="item.complement"
          />
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="5">
          <v-text-field
            id="district"
            name="district"
            label="Bairro"
            dense
            outlined
            required
            :rules="[required]"
            v-model="item.district"
          />
        </v-col>
        <v-col cols="12" xl="2" lg="2" md="2" sm="3">
          <BaseStates
            id="state"
            name="state"
            label="Estado"
            required
            :rules="[required]"
            v-model="item.state"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="5">
          <v-text-field
            id="city"
            name="city"
            label="Cidade"
            dense
            outlined
            required
            :rules="[required]"
            v-model="item.city"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="4" v-if="editMode">
          <v-autocomplete
            id="situation"
            name="situation"
            label="Status"
            dense
            outlined
            required
            :items="situation"
            :rules="[required]"
            v-model="item.situation"
          />
        </v-col>
        <v-col cols="12" xl="5" lg="5" md="5" sm="6">
          <v-checkbox
            id="applyIof"
            name="applyIof"
            label="Aplicar IOF na fatura?"
            class="mt-1"
            v-model="item.applyIof"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <h5 class="b-title__icon">
            Tipo de produto

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="openAns"
                >
                  fa-solid fa-circle-info
                </v-icon>
              </template>
              <span>Deseja verificar o registro ANS? Clique aqui!</span>
            </v-tooltip>
          </h5>
        </v-col>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="4"
          sm="4"
          v-for="(item, index) in item.typeProducts"
          :key="index"
        >
          <v-checkbox
            class="ma-0"
            color="primary"
            :label="`${item.type}?`"
            v-model="item.active"
            @change="setProduct(item)"
          />
          <v-text-field
            type="number"
            dense
            outlined
            maxlength="50"
            :label="item.type === 'Vida' ? 'Número da SUSEP' : 'Número da ANS'"
            :required="item.active"
            :rules="[required]"
            v-if="item.active"
            v-model="item.ansRegistration"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row>
        <v-col class="text-right">
          <BaseButton
            id="btn-back"
            name="btn-back"
            color="primary"
            title="Voltar"
            outlined
            @click="back"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            color="primary"
            title="Salvar"
            type="submit"
            :disabled="!valid"
            v-permission="{
              roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
              operator: '==='
            }"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  props: {
    editMode: {
      type: Boolean
    }
  },

  data: () => ({
    valid: true,
    situation: ['Ativo', 'Cancelado'],
    item: {
      id: null,
      logo64: null,
      insuranceCompanyCode: null,
      socialName: null,
      fantasyName: null,
      document: null,
      zipCode: null,
      address: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      state: null,
      situation: null,
      applyIof: false,
      typeProducts: [
        { type: 'Saúde', ansRegistration: null, active: false },
        { type: 'Vida', ansRegistration: null, active: false },
        { type: 'Odonto', ansRegistration: null, active: false }
      ]
    }
  }),

  created() {
    if (this.editMode) {
      this.search();
    }
  },

  methods: {
    async search() {
      try {
        const { id } = this.$route.params;
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getById(id);

        if (status === 200) {
          this.item = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    fill(params) {
      if (params) {
        this.item.address = params.logradouro;
        this.item.district = params.bairro;
        this.item.city = params.localidade;
        this.item.state = params.uf;
      }
    },

    back() {
      this.$emit('back');
    },

    openAns() {
      window.open('http://www.ans.gov.br/ConsultaPlanosConsumidor/', '_blank');
    },

    setProduct(item) {
      if (!item.active) {
        item.ansRegistration = null;
      }
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      if (!this.item.typeProducts.some(x => x.active === true)) {
        showMessage('error', 'Informe ao menos um produto!');
        return;
      }

      if (!this.item.logo64) {
        showMessage('error', 'Obrigatório adicionar uma imagem!');
        return;
      }

      const params = Object.assign({}, this.item);
      params.document = unmask(params.document);
      params.zipCode = unmask(params.zipCode);
      params.typeProducts = params.typeProducts.filter(x => x.active === true);

      if (this.editMode) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.postInsurance(
          params
        );

        if (status === 200) {
          await insuranceCompanyService.uploadLogo(params);
          showMessage('success', 'Operação realizada com sucesso');
          this.nextTab(data.id, params.document);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status } = await insuranceCompanyService.putInsurance(params);

        if (status === 200) {
          if (params.logo64 instanceof File) {
            await insuranceCompanyService.uploadLogo(params);
          }
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    nextTab(id, cnpj) {
      if (!this.editMode) {
        this.$router.push({
          name: 'operadoras-edit',
          params: { id, cnpj }
        });
      }
    }
  }
};
</script>
